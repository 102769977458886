html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: #FFDF0A;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
input:focus {
    outline:none;
}
.input-box {
  position: relative;
  width: 100%;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #DEDEDE;
  box-sizing: border-box;
  border-radius: 6px;
  px: 16px; 
  py: 15px;
  padding: 4px;
}

.top{
  top: 100%;
}

.xx {
  margin-top: 10%;
}

.content-below-head{
  height : calc(100vh - 16vh)
}
.cbh-1{
  height : calc(100vh - 17vh)
}
.tab-h-m {
  height : 56vh;
}
.tab-h-d {
  height: calc(66vh - 8px);
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.styled-scrollbar::-webkit-scrollbar{
  background-color: transparent;
  width: 8px; 
}

.styled-scrollbar::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #D3D3D3; 
}

.flip-container:hover .flipper, .flip-container.hover .flipper {
  transform: rotateY(360deg);
}

.flipper {
	transition: 1.6s;
	transform-style: preserve-3d;
	position: relative;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
}

.front {
	backface-visibility: hidden;
	top: 0;
	left: 0;
}

.front {
	transform: rotateY(0deg);
}
.embed-container{
  padding: 10px;
}
.embed-container iframe {
  position:absolute;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
  width: 100%;
  height: 100%;
}
