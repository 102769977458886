.ToolbarPlugin__dialogActions {
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-top: 20px;
}

.ToolbarPlugin__dialogButtonsList {
  display: flex;
  flex-direction: column;
  justify-content: right;
  margin-top: 20px;
}

.ToolbarPlugin__dialogButtonsList button {
  margin-bottom: 20px;
}